(function() {
	'use strict';

	angular.module('FYN').service('ShoppingService', function($http) {

		this.getShoppingTypes = function() {
			return $http.get('/api/shopping/types');
		}


	})
})();