(function() {
	'use strict';

	angular.module('FYN').service('ValidatorService', function() {

		this.email = function() {

		};

		this.password = function(str) {
			if(str.length > 8) {				
				return false;
			}
		};

		this.zipCode = function() {

		};

	})

})();