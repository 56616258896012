(function() {

	'use strict';

	angular.module('FYN').service('MapService', function($http) {

		this.addTip = function(postdata) {
			var postdata = {
				userid: postdata.userid,
				locationid: postdata.locationid,
				isPrivate: postdata.isPrivate,
				tip: postdata.tip
			};
			return $http.post('/api/tips/add', postdata);
		};





		this.getUserLikes = function(userid) {
			return $http.get('/api/likes/'+userid);

		}


	});
	
})();