angular.module('FYN').service('NewsletterService', function($http) {
	
	this.signup = function(email, zipcode) {
		var data = {
			email: email,
			zipcode: zipcode
		};
		return $http.post('/api/newsletter/signup', data);
	}


	this.setZipCode = function(email, zipcode) {

		return $http.get('/api/newsletter/setZipCode/'+email+'/'+zipcode);

	}

});