angular.module('FYN').service('AuthService', function($http, $q, $auth) {




	this.redirectOldUserLink = function(userid) {

		return $http.get('/api/redirectolduser/'+userid);

	}




	this.login = function(username,password) {
		var credentials = {
			username: username,
			password: password
		}
		return $auth.login(credentials);
	};
	this.checkExisting = function(email) {
		return $http.get('/api/auth/existing/'+email);
	}
	this.resetPassword = function(email) {
		var data = {
			email: email
		}
		return $http.post('/api/auth/password/reset', data);
	}
	this.completeResetPassword = function(credentials) {
		var data = {
			email: credentials.email,
			verifytoken: credentials.verifytoken,
			verifyid: credentials.verifyid,
			password: credentials.password
		}
		return $http.post('/api/auth/password/postreset', data);
	}
	this.register = function(nonfyn, email, password, handle, zipcode, newsletter) {
		var credentials = {
			nonfyn: nonfyn,
			email: email,
			password: password,
			handle: handle,
			zipcode: zipcode,
			newsletter: newsletter
		};
		return $auth.signup(credentials);
	};
	this.addHandle = function(credentials) {
		var data = {
			userid: credentials.userid,
			email: credentials.email,
			handle: credentials.handle,
			zipcode: credentials.zipcode,
			newsletter: credentials.newsletter
		};
		return $http.post('/api/auth/addHandle', data)
	}
	this.verify = function(credentials) {
		return $http.get('/api/auth/verify/'+credentials.username+'/'+credentials.id+'/'+credentials.token);
	};
	this.socialLogin = function(provider) {
		return $auth.authenticate(provider);
	};
	this.logout = function() {
		localStorage.removeItem('satellizer_token');
	};
	this.isAdmin = function() {
		if(this.check()) {
			return $auth.getPayload().role >= 16;
		}
	}
	this.isSuperAdmin = function() {
		if(this.check()) {
			return $auth.getPayload().role === 32;
		}
	}
	this.check = function() {
		return $auth.isAuthenticated();
	};
	this.getLoggedInUser = function() {
		var userid = $auth.getPayload().sub;
		return $http.get('/api/auth/user/'+userid);
	};
	this.addLike = function(lid) {
		var data = {
			locationid: lid,
			userid: $auth.getPayload().sub
		}
		return $http.post('/api/likes/add/'+data.userid+'/'+data.locationid, data);
	}
	this.removeLike = function(lid) {

		var userid = $auth.getPayload().sub;
		console.log(lid);
		console.log(userid);
		return $http.get('/api/likes/remove/'+userid+'/'+lid);
	}
});
