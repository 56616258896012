angular.module('FYN').service('NeighborhoodService', function($http) {


	this.find = function(slug) {
		return $http.get('/api/neighborhoods/'+slug);
	}

	this.findById = function(id) {
		return $http.get('/api/neighborhoods/'+id);

	}

	this.banner = function(slug) {
		return $http.get('/api/neighborhoods/banner/'+slug);

	}

	this.all = function() {
		return $http.get('/api/neighborhoods/all');
	}


	this.getListingCounts = function(slug) {
		return $http.get('/api/neighborhoods/listings/counts/'+slug);
	}

	this.getCardData = function(slug) {
		return $http.get('/api/neighborhoods/cards/'+slug);
	}
	this.getBrickImages = function(slug) {
		return $http.get('/api/neighborhoods/bricks/'+slug);
	}


});
