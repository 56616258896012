(function() {
	'use strict';
	angular.module('FYN').service('MyNolaService', function($http) {
		this.updateSort = function(likesArray, person) {
			var postdata = {
				likesArray: likesArray,
				person: person
			}
			return $http.post('/api/mynola/sort', postdata);
		}
	});
})();
