(function() {
	'use strict';

	angular.module('FYN').service('NightlifeService', function($http) {

		this.getNightlifeFeatures = function() {
			return $http.get('/api/nightlife/features');
		}


	})
})();