(function() {
	'use strict';

	angular.module('FYN')
	.service('ListService', function($http) {



		this.getCategories = function() {
			return $http.get('/api/lists/categories');
		}

		this.getLists = function() {
			return $http.get('/api/lists/all');
		}

		this.getListsByCategory = function(category) {
			return $http.get('/api/lists/category/'+category);

		}

		this.getMoreLists = function(start,amount) {
			return $http.get('/api/lists/more/'+start+'/'+amount);
		}
		this.getTags =function() {
			return $http.get('/api/lists/tags');
		}
		this.getDeals = function() {
			return $http.get('/api/deals');
		}
		this.setCategory = function(catid, listid) {
			var postdata = {
				catid: catid,
				listid: listid
			}
			return $http.post('/api/admin/list/category', postdata);
		}
		this.setActive = function(listid, status) {
			var data = {
				listid: listid,
				status: status
			}
			return $http.post('/api/list/status', data);
		}
		this.updateTitle = function(listid, title, slug) {
			var data = {
				listid: listid,
				title: title,
				slug: slug
			};
			return $http.post('/api/list/title', data);
		}
		this.updateDescription = function(listid, text) {
			var data = {
				text: text,
				listid: listid
			}
			return $http.post('/api/list/description', data);
		}

		this.updateTourDistance = function(listid, tourDistance) {
			var data = {
				tourDistance: tourDistance,
				listid: listid
			}
			return $http.post('/api/list/tourdistance', data);
		}
		this.updateTourDuration = function(listid, tourDuration) {
			var data = {
				tourDuration: tourDuration,
				listid: listid
			}
			return $http.post('/api/list/tourduration', data);
		}

		this.updateVimeoId = function(listid, text) {
			var data = {
				text: text,
				listid: listid
			}
			return $http.post('/api/list/vimeoid', data);
		}

		this.createList = function(title, slug, category, description) {
			var data = {
				title: title,
				slug: slug,
				category: category,
				description: description
			};
			return $http.post('/api/lists/create', data);
		};
		this.deleteList = function(listid) {
			var data = {
				listid: listid
			}
			return $http.post('/api/list/delete', data);
		}
	})
})();