(function() {
	'use strict';
	angular.module('FYN').service('ResultsService', function($http) {

		this.locations = function() {};
		this.users = function() {
			return $http.get('/api/users');
		};
		this.getAdmins = function() {
			return $http.get('/api/users/admins');
		}
		this.list = function(listid) {
			return $http.get('/api/lists/'+listid);
		};
		this.lists = function() {
			return $http.get('/api/lists/all');
		};
		this.categories = function() {
			return $http.get('/api/categories');
		};

		this.search = function(searchterm, params) {
			return $http.get('/api/search/'+searchterm+'/'+params.latitude+'/'+params.longitude);
		};
		this.broadSearch = function(searchterm, params) {
			//return 'hi there';
			return $http.get('/api/broadsearch/'+searchterm+'/'+params.latitude+'/'+params.longitude);
		};


		this.getTips = function() {
			return $http.get('/api/tips/get');
		}

		this.editTip = function(tipId, tipContent) {
			var postdata = {
				tipId: tipId,
				tipContent: tipContent
			};
			return $http.post('/api/tips/edit', postdata);
		}
		this.deleteTip = function(tipId) {
			return $http.get('/api/tips/delete/'+tipId);
		}
		this.deleteTipFromLocation = function(tipId, lid) {
			return $http.get('/api/tips/delete/'+tipId+'/'+lid);

		}


		this.getAllLists = function() {
			return $http.get('/api/lists/all');
		};

		this.getList = function(category,listid,slug) {
			console.log(category);
			console.log(listid);
			console.log(slug);

			return $http.get('/api/list/'+category+'/'+listid+'/'+slug);
		};

		this.getTour = function(slug) {
			return $http.get('/api/tour/'+slug);
		}
		this.getTourMedia = function(listid, locationid) {

			return $http.get('/api/tour/media/'+listid+'/'+locationid);

		}

		this.getCustomList = function(tags) {
			return $http.get('/api/custom/list/'+tags);
		}



		this.getLocationSlides = function(locationid, custom) {
			var postdata = {
				locationid: locationid,
				custom: custom
			};
			return $http.post('/api/tools/slides/locate', postdata);
			//return $http.get('http://www.neworleansonline.com/tools/fyn/locate_slides.php?locationid='+locationid);
		}


		this.getLocation = function(locationid) {
			
			return $http.get('/api/location/'+locationid);
		}
		
		this.getTourLocation = function(locationid, listid) {
			return $http.get('/api/tourlocation/'+listid+'/'+locationid);
		}
		
		this.accommodations = function(params) {
			return $http.get('/api/map/stay/'+params.neighborhood+'/'+params.lodgingtype+'/'+params.amenities+'/'+params.name+'/'+params.latitude+'/'+params.longitude);
		};
		this.eat = function(params) {
			return $http.get('/api/map/eat/'+params.neighborhood+'/'+params.cuisinetype+'/'+params.attire+'/'+params.pricerange+'/'+params.when+'/'+params.name+'/'+params.latitude+'/'+params.longitude);
		};
		this.drink = function(params) {
			return $http.get('/api/map/drink/'+params.neighborhood+'/'+params.features+'/'+params.name+'/'+params.latitude+'/'+params.longitude);
		};
		this.attractions = function(params) {
			return $http.get('/api/map/attractions/'+params.neighborhood+'/'+params.features+'/'+params.name+'/'+params.latitude+'/'+params.longitude);
		};
		this.shopping = function(params) {
			return $http.get('/api/map/shopping/'+params.neighborhood+'/'+params.shoppingtypes+'/'+params.name+'/'+params.latitude+'/'+params.longitude);
		};
		this.livemusic = function(params) {
			return $http.get('/api/map/livemusic/'+params.neighborhood);
		}
		this.getCuisineTypes = function() {
			return $http.get('/api/cuisine/types');
		}


		this.getTours = function() {
			return $http.get('/api/tours/all');
		}






	});
})();
