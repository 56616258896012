angular.module('FYN').service('AdminService', function($http) {

	this.addAdmin = function(params) {
		var postdata = {
			email: params.email,
			privs: params.privs
		}
		return $http.post('/api/users/admin/add', postdata);
	};
	this.removeAdmin = function(email) {
		var postdata = {
			email: email
		}
		return $http.post('/api/users/admin/remove', postdata);
	};

	this.searchUser = function(userQuery) {
		return $http.get('/api/users/search/'+userQuery);

	}





	this.search = function(squery) {

		return $http.get('/api/admin/search/'+squery);

	}
	this.getCategory = function(id) {
		return $http.get('/api/categories/'+id);
	}
	this.createCategory = function(title,thumbnail,slug,description) {
		var data = {
			title: title,
			thumbnail: thumbnail,
			slug: slug,
			description: description
		}
		return $http.post('/api/categories/new', data);
	};
	this.deleteCategory = function(id) {
		var postdata = {
			id: id
		}
		return $http.post('/api/categories/delete', postdata);
	}
	this.updateCustomThumbUrl = function(listid, url) {
		var postdata = {
			listid: listid,
			url: url
		}
		return $http.post('/api/customthumbnailsrc/update', postdata);
	}
	this.updateCategory = function(id, status, thumbnail, title, slug, description) {
		var postdata = {
			id: id,
			status: status,
			thumbnail: thumbnail,
			title: title,
			slug: slug,
			description: description
		}
		return $http.post('/api/categories/update', postdata);
	}
	this.updateListEntries = function(listid,list,item) {
		var data = {
			listid: listid,
			list: list,
			item: item
		}
		return $http.post('/api/lists/update', data);
	};
	this.deleteListEntry = function(listid,entry) {
		var data = {
			listid: listid,
			entry: entry
		}
		
		return $http.post('/api/lists/delete', data);
	};
	this.deleteLocationFromList = function(listid, locationid) {
		return $http.get('/api/lists/deleteLocation/'+listid+'/'+locationid);
	}
	this.editNeighborhood = function(slug) {
		return $http.get('/api/neighborhoods/edit/'+slug);
	};
	this.getCardData = function(slug) {
		return $http.get('/api/neighborhoods/cards/'+slug);
	}
	this.updateCardData = function(slug, tab, card, data) {
		var postdata = {
			slug: slug,
			tab: tab,
			card: card,
			data: data
		}
		return $http.post('/api/neighborhoods/cards/update', postdata);
	};
	this.updateDetailPageDescription = function(slug, data) {
		var postdata = {
			slug: slug,
			data: data
		};
		return $http.post('/api/neighborhoods/detail/update', postdata);
	};
	this.updateOverviewTeaser = function(slug, data) {
		var postdata = {
			slug: slug,
			data: data
		};
		return $http.post('/api/neighborhoods/overview/update', postdata);
	};
	this.addCustomLocation = function(params) {
		var postdata = {
			title: params.locationName,
			geoLatitude: params.geoLatitude,
			geoLongitude: params.geoLongitude,
			//audio: params.audio,
			//bannerimage: params.bannerImage,
			//thumbnailimage: params.thumbnailImage,
			description: params.description
		}
		return $http.post('/api/customlocations/add', postdata);
	}
	this.deleteCustomLocation = function(locationid) {
		var postdata = {
			locationid: locationid
		};
		return $http.post('/api/customlocations/remove', postdata);

	}

	this.updateCustomLocation = function(params) {

		var postdata = {
			locationid: params.locationid,
			title: params.locationName,
			geoLatitude: params.geoLatitude,
			geoLongitude: params.geoLongitude,
			coLocation: params.coLocation,
			audio: params.audio,
			video: params.video,
			//bannerimages: params.bannerImages,
			//thumbnailimage: params.thumbnailImage[0],
			description: params.description
		}
		console.log(postdata);
		return $http.post('/api/customlocations/update', postdata);
	}
	this.getCustomLocation = function(locationID) {
		return $http.get('/api/customlocations/'+locationID);
	}


	this.getCustomLocations = function() {
		return $http.get('/api/customlocations');
	}



	this.getCuratedImages = function(slug) {
		return $http.get('/api/images/curated/'+slug);
	}
	this.disableCuratedImage = function(neighborhoodID,filename) {
		return $http.get('/api/images/curated/disable/'+neighborhoodID+'/'+filename);
	}
	this.enableCuratedImage = function(neighborhoodID,filename) {
		return $http.get('/api/images/curated/enable/'+neighborhoodID+'/'+filename);
	}
	this.deleteCuratedImage = function(neighborhoodID, filename) {
		return $http.get('/api/images/curated/delete/'+neighborhoodID+'/'+filename);
	}

	this.addLocationInstance = function(instance) {


		return $http.post('/api/admin/instance', instance);

	}




	this.getContext = function(locationid, listid) {

		return $http.get('/api/admin/instance/'+locationid+'/'+listid);

	}


	this.lists = function() {
		return $http.get('/api/admin/lists');
	}

	this.adminList = function(listid) {
		return $http.get('/api/admin/list/'+listid);
	}

	this.updateListSort = function(sortedCollection) {
		var postdata = {
			sortedCollection: sortedCollection
		}
		return $http.post('/api/admin/lists/sort', postdata);
	}
	this.updateCategorySort = function(sortedCollection) {
		var postdata = {
			sortedCollection: sortedCollection
		}
		return $http.post('/api/admin/categories/sort', postdata);
	}
	this.tags = function() {
		return $http.get('/api/admin/tags');
	}


	this.getBannerImages = function(locationid, listid) {
		return $http.get('/api/admin/bannerimages/'+locationid+'/'+listid);
	}
	
	this.removeInstanceBanner = function(obj) {

		return $http.post('/api/admin/instancebanner/remove', obj);


	}
	this.deleteBanner = function(filename, locationid) {
		var postdata = {
			filename: filename,
			locationid: locationid
		}
		return $http.post('/api/admin/deleteCustomLocationBanner', postdata);

	}

	this.getInstanceThumbnail = function(locationid, listid) {
		return $http.get('/api/admin/instancethumbnail/'+locationid+'/'+listid);

	}

	this.getThumbnailImages = function(locationid) {
		return $http.get('/api/admin/thumbnailimages/'+locationid);
	}










});
